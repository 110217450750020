import React, {useState} from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";

import {getPositions} from "../System/Subject/People/People";

const TableData = ({data}) => {


    if (!data) return null


    let columns = [
        {Header: 'Jméno Příjmení', accessor: d => {
              return(<>
                <div>{d.name}</div>
                <div>{d.email}</div>
               </>)
            }},
        {Header: 'Subject', accessor: "subjectName"},
        {Header: 'Pozice', accessor: d => {
                return d.positions.map(i=>{
                    return <div>{getPositions(i)}</div>
                })
            }},
        {Header: 'Obchodník', accessor: "dealerName"},
        {Header: 'Key account', accessor: "keyAccountName"},
    ];




    return (
        <Widget>
            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
