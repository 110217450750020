import React, {useState} from "react";
import {Button} from "../../../../components/My/Button";
import {apiDeleteSubjectPeople} from "../Actions";
import Toast from "cogo-toast";
import Datatable from "../../../../components/datatable";
import ModalPeople from "./ModalPeople";
import ModalAccept from "../../../../components/My/ModalAccept";


export const getPositions = (p) => {
    switch (p){
        case 'TICKETING': return "Ticketing"
        case 'ECONOMIST': return "Ekonom"
        case 'BOSS': return "Jednatel"
        case 'MARKETING': return "Marketing"
        case 'DEALER': return "Obchodník"
        case 'IT': return "IT specialista"
    }
}

const People = ({data, renew}) => {

    let [modalPeople, setModalPeople] = useState(false)
    let [modalDeletePeople, setModalDeletePeople] = useState(false)
    let [people, setPeople] = useState(false)

    let columns = [
        {Header: 'Jméno a Příjmení', accessor: d=>{
            return <>
                <div>{d.name}</div>
                <div className={"text-amber-700"}>{d.note}</div>
            </>
            }},
        {Header: 'E-mail', accessor: "email"},
        {Header: 'Telefon', accessor: "phone"},
        {Header: 'Pozice', accessor: d => {
            return d.positions.map(i=>{
                return <div>{getPositions(i)}</div>
            })
        }},
        {
            Header: 'Možnosti', accessor: d => (
                <div className={"flex flex-row"}>
                <Button text={"Upravit"} onClick={()=>{
                    setPeople(d)
                    setModalPeople(true)
                }} />
                    <Button extraStyle={"ml-1"} text={"Smazat"} onClick={()=>{
                        setPeople(d)
                        setModalDeletePeople(true)
                    }} />
                </div>
            )
        }
    ];

    const deletePeople = () => {
        apiDeleteSubjectPeople(data.id, people.id, (data)=>{
            renew()
            setModalPeople(false)
            setPeople(null)
            Toast.success("Dokončeno.")
        }, error =>{
            Toast.warn("Došlo k neočekávané chybě.")
        })
    }

    return (
        <div>
            <Datatable data={data.subjectPeople} columns={columns}/>
            <Button text={"Přidat kontakt"} onClick={()=>setModalPeople(true)} />
            <ModalPeople visible={modalPeople} close={(isRenew)=>{
                if(isRenew) renew()
                setModalPeople(false)
                setPeople(null)}
            } subjectId={data.id} people={people}/>

            <ModalAccept body={<div>Opravdu, chcete smazat kontakt?</div>} title={"Smazat kontakt ?"}
                         visible={modalDeletePeople} close={()=>{
                                setModalDeletePeople(false)
                                setPeople(null)
                            }} acceptFunction={deletePeople}
            />
        </div>
    )

}

export default People
