import React from "react"
import {Form} from "react-final-form";
import {Button} from "../../components/My/Button";
import InputField from "../../FinalForm/InputField";
import CheckboxField from "../../FinalForm/CheckboxField";


const UserForm = ({acceptFunction, init, loading}) => {

    return (
        <Form onSubmit={acceptFunction} initialValues={init} render={({handleSubmit, values}) => {
            return (
                <div>
                    <div className={"flex flex-wrap w-full"}>
                        <div className={"w-1/2"}>
                            <InputField label={"Logovací e-mail"} name={"email"} isMandatory={true}/>
                        </div>
                        <div className={"w-1/2"}>
                            <InputField label={"Jméno uživatele"} name={"name"} isMandatory={true}/>
                        </div>
                        {init &&
                            <div className={"w-1/2"}>
                                <CheckboxField label={"Chci zadat nové heslo"} name={"isNewPassword"}/>
                            </div>
                        }
                        <div className={"w-full mb-3"}>
                            {(values.isNewPassword || !init) &&
                                <InputField label={!init ? "Heslo" : "Nové heslo"} name={"password"}
                                            isMandatory={values.isNewPassword || !init}
                                            disabled={!values.isNewPassword && init}/>
                            }
                        </div>
                        <div className={"flex flex-wrap"}>
                            <div className={"flex mr-2"}>
                                <CheckboxField label={"Aktivní"} name={"active"}/>
                            </div>
                            <div className={"flex mr-2"}>
                                <CheckboxField label={"Obchodník"} name={"isDealer"}/>
                            </div>
                            <div className={"flex mr-2"}>
                                <CheckboxField label={"Key account"} name={"isKeyAccount"}/>
                            </div>
                        </div>
                        <div className={"w-full flex flex-col"}>
                            <h4 className={"w-full mt-3 mb-2"}>Fakturace a banka</h4>
                            <div className={"flex w-full flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Zobrazit list plateb"}
                                                   name={"rights.bankInvoices.listPayment"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Objednávky fakturace"} name={"rights.bankInvoices.order"}/>
                                </div>
                            </div>

                            <h4 className={"w-full mt-3 mb-2"}>Vyúčtování</h4>
                            <div className={"flex flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Zobrazit vyúčtování"} name={"rights.billing.view"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Úprava vyúčtování subjektu"}
                                                   name={"rights.billing.changeSubject"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Úprava hlavního nastavení"}
                                                   name={"rights.billing.mainChange"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Nahrávání dat z bank"} name={"rights.billing.csobImport"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Export dat"} name={"rights.billing.export"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Export dat do abry"} name={"rights.billing.exportAbra"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Přepočítat všechny subjekty"}
                                                   name={"rights.billing.recalculate"}/>
                                </div>
                            </div>
                            <h4 className={"w-full mt-3 mb-2"}>Systémová práva</h4>
                            <div className={"flex flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Uživatelé"} name={"rights.system.users.view"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Subjekty"} name={"rights.system.subject.view"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Nastavení"} name={"rights.system.config"}/>
                                </div>
                            </div>
                            <h4 className={"w-full mt-3 mb-2"}>Statistiky</h4>
                            <div className={"flex flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Denní statistika"} name={"rights.stats.daily"}/>
                                </div>
                            </div>
                            <h4 className={"w-full mt-3 mb-2"}>Dashboard</h4>
                            <div className={"flex flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Upozornění na konce smluv"}
                                                   name={"rights.dashboard.contract"}/>
                                </div>
                            </div>
                            <h4 className={"w-full mt-3 mb-2"}>Subjekty</h4>
                            <div className={"flex flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Zobrazit kalendář akcí"}
                                                   name={"rights.subject.subjectAction.view"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Vytvořit a editovat"} name={"rights.subject.subjectAction.create"}/>
                                </div>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Smazat akci"} name={"rights.subject.subjectAction.delete"}/>
                                </div>
                            </div>
                            <h4 className={"w-full mt-3 mb-2"}>Kontakty</h4>
                            <div className={"flex flex-wrap"}>
                                <div className={"flex mr-2"}>
                                    <CheckboxField label={"Zobrazit kontakty"} name={"rights.contact"}/>
                                </div>
                            </div>
                            <div className={"w-full mt-3"}>
                                <Button text={"Uložit"} onClick={() => handleSubmit()} loading={loading}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}/>
    )

}

export default UserForm
