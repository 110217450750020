import {instance} from "../../config/axios-cnf";


export const apiGetContact = (values, callback, error) => {
    if(values.typeExport === 'XLS_LIST'){
        instance.get("/api/contact", {params: values, responseType: 'arraybuffer'})
            .then(response => callback(response))
            .catch(err => error(err))
    }else{
        instance.get("/api/contact", {params: values})
            .then(response => callback(response.data))
            .catch(err => error(err))
    }

}
