import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetBilling = (id, filter, callback, error) => {
    if (id) {
        instance.get("/api/billing/" + id)
            .then(response => callback(response.data))
            .catch(err => error(err))
    } else {
        instance.get("/api/billing/list", {params: filter})
            .then(response => callback(response.data))
            .catch(err => error(err))
    }
}


export const apiPostImportPaymentGatewayFile = (billingId, bankId, file, callback, error) => {
    instance.post(`/api/billing/${billingId}/bank/${bankId}`, file, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))

}

export const apiGetBankPayment= (billingId, type, callback, error) => {

    if (type === "XLS_LIST") {
        instance.get(`/api/billing/${billingId}/bank`, {params: {type}, responseType: 'arraybuffer'})
            .then(response => callback(response))
            .catch(err => error(err))
    } else {
        instance.get(`/api/billing/${billingId}/bank`, {params: {type}})
            .then(response => callback(response.data))
            .catch(err => error(onErrorFunction(err)))
    }


}

// Ziskani detailu vyuctovani

export const apiGetBillingDetail = (id, callback, error) => {
    instance.get(`/api/billing/${id}/detail`)
        .then(response => callback(response.data))
        .catch(err => error(err))

}


// Ziskání seznamu subjektů s vyučtováním
export const apiGetSubjectBillingList = (billingId, filters, callback, error) => {
    instance.get(`/api/billing/${billingId}/list`, {params: filters})
        .then(response => callback(response.data))
        .catch(err => error(err))

}
export const apiChangeBillingListItem = (billingId, values, callback, error) => {
    instance.post(`/api/billing/${billingId}/list`, values)
        .then(response => callback(response.data))
        .catch(err => error(err))

}


// GET billing subject
export const apiGetBillingDetailBySubject = (billingId, subjectId, type, callback, error) => {
    if (type === "xls") {
        instance.get(`/api/billing/${billingId}/${subjectId}/detail`, {params: {type}, responseType: 'arraybuffer'})
            .then(response => callback(response))
            .catch(err => error(err))
    } else {
        instance.get(`/api/billing/${billingId}/${subjectId}/detail`)
            .then(response => callback(response.data))
            .catch(err => error(err))
    }
}

export const apiGetRecalculateSubjectBilling = (billingId, subjectId, callback, error) => {
    instance.get(`/api/billing/${billingId}/${subjectId}/calculate`, {params: {change: true}})
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiSaveBillingSubject = (billingId, subjectId, data, callback, error) => {
    instance.post(`/api/billing/${billingId}/${subjectId}`, {data})
        .then(response => callback(response.data))
        .catch(err => error(err))

}

export const apiSetAdvanceToSubject = (billingId, subjectId, data, callback, error) => {
    instance.post(`/api/billing/${billingId}/${subjectId}/advance`, data)
        .then(response => callback(response.data))
        .catch(err => error(err))

}

export const apiRecalculateBilling = (billingId, values, callback, error) => {
    instance.get(`/api/billing/${billingId}/recalculate`, {params: values})
        .then(response => callback(response.data))
        .catch(err => error(err))

}

// function of list

export const apiGetExportBilling = (billingId, subjectId, type, callback, error) => {
    if (type === "XLS_LIST") {
        instance.get(`/api/billing/${billingId}/export`, {params: {type, subjectId}, responseType: 'arraybuffer'})
            .then(response => callback(response))
            .catch(err => error(err))
    } else if (type === "ABRA_XML" || type === "ABRA_XML_MOBILE") {
        instance.get(`/api/billing/${billingId}/export`, {params: {type, subjectId}})
            .then(response => callback(response.data))
            .catch(err => error(err))
    } else if (type === "ZIP_XLS_PER_SUBJECT") {
        instance.get(`/api/billing/${billingId}/export`, {params: {type, subjectId}, responseType: 'arraybuffer'})
            .then(response => callback(response))
            .catch(err => error(err))
    }
}


