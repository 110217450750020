import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import Select from "react-select";


const SelectField = (props) => {


    const getParse = (value) => {
        if (value === null) return null;
        if (props.isMulti) {
            return value.map(item => item.value)
        } else {
            return value.value;
        }
    };

    const getValue = (value) => {

        if (!props.isMulti) {
            let item = props.options.find(item => item.value === value)
            if (!item) {
                let final = null;
                props.options.map(option => {
                    if (option.options) {
                        let _item = option.options.find((itemValue) => itemValue.value === value)
                        if (!_item) return null;
                        final = {value: _item.value, label: _item.name}
                    }
                })
                return final;
            }else {
                return item
            }
        } else {
            let array = [];
            if (value instanceof Array) {
                value.map(item => {
                    props.options.map(option => {
                        if (option.options) {
                            let _item = option.options.find((itemValue) => itemValue.value === item)
                            if (!_item) return null;
                            array.push({
                                value: _item.value, label: item.name
                            })
                        } else if (item === option.value) {
                            array.push({value: option.value, label: option.label})
                        }
                    })
                })
            }


            return array
        }
    };

    const errorMessage = (message) => {
        return (
            <span style={{color: "red", marginRight: "10px"}} className={"text-sm"}>
                <i className="fa fa-arrow-up"> </i> {message}
            </span>
        );
    };

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole");
        }
        return null;
    };


    return (
        <Field
            {...props}
            name={props.name}

            validate={(value) => {
                if (props.isMandatory) {
                    if (!value) return true;
                }
                return false;
            }}
            render={(_props) => {

                if(!props.editable){
                    return <>
                        <div className={"font-bold"}>
                            {props.label && props.label + ": "}
                            {props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}
                        </div>
                        <div className={"text-base"}>{(getValue(_props.input.value.value))?.label}</div>
                    </>
                } else {

                    return (
                        <div className={"w-full p-1"} data-test={props.dataTest}>
                            <div className={"font-bold"}>
                                {props.label && props.label + ": "}
                                {props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}
                            </div>
                            <Select
                                {...props}
                                styles={{
                                    menu: (base) => ({...base, zIndex: 9999}),
                                    menuPortal: (base) => ({...base, zIndex: 9999}),
                                }}
                                isMulti={props.isMulti}
                                placeholder={props.placeholder ? props.placeholder : "Vyberte..."}
                                onChange={(value) => {
                                    _props.input.onChange(value)
                                    if (props.change) {
                                        props.change(value);
                                    }
                                }}
                                options={props.options}
                                value={_props.input.value}
                                isDisabled={props.disabled}
                                isClearable
                            />
                            {renderError(_props)}
                        </div>
                    )
                }
                }
            }
            parse={getParse}
            format={getValue}
        />
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    isMandatory: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.object,
    change: PropTypes.func,
    editable: PropTypes.bool
};
SelectField.defaultProps = {
    isMandatory: false,
    isMulti: false,
    editable: true
};

export default SelectField;
