import React from "react"
import {Form} from "react-final-form";
import InputField from "../../../FinalForm/InputField";
import {Button} from "../../../components/My/Button";
import SubjectTypeField from "../../../FinalForm/api/SubjectTypeField";
import UsersField from "../../../FinalForm/api/UsersField";
import CountryField from "../../../FinalForm/api/CountryField";
import DateTimeField from "../../../FinalForm/DateTimeField";
import CheckboxField from "../../../FinalForm/CheckboxField";
import SelectField from "../../../FinalForm/SelectField";


const SubjectForm = ({acceptFunction, init, loading}) => {


        return (
            <Form onSubmit={acceptFunction} initialValues={init} render={({handleSubmit, values}) => {
                return (
                    <div>
                        <div className={"flex flex-wrap w-full"}>
                            <div className={"w-1/3"}>
                                <InputField label={"Název subjektu"} name={"name"} isMandatory={true}/>
                            </div>
                            <div className={"w-1/3"}>
                                <InputField label={"Identifikační číslo"} name={"identifyNumber"} isMandatory={true}/>
                            </div>
                            <div className={"w-1/3"}>
                                <InputField label={"Url adresa"} name={"urlAddress"} isMandatory={true}/>
                            </div>
                            <div className={"w-1/2"}>
                                <SubjectTypeField name={"typeSubject.id"} label={"Typ subjektu"} validate={value => !value}/>
                            </div>
                            <div className={"w-1/2"}>
                                <CountryField name={"country.id"} label={"Země"} validate={value => !value}/>
                            </div>
                            <div className={"w-1/2"}>
                                <UsersField name={"keyAccount.id"} label={"Key account"} validate={value => !value} filters={{onlyKeyAccount: true}}/>
                            </div>
                            <div className={"w-1/2"}>
                                <UsersField name={"dealer.id"} label={"Obchodník"} validate={value => !value}/>
                            </div>
                            <div className={"w-1/2"}>
                                <DateTimeField name={"contractFrom"} label={"Začátek spolupráce"} isMandatory={false} />
                            </div>
                            <div className={"w-1/2"}>
                                <DateTimeField name={"contractTo"} label={"Datum konce smlouvy"} isMandatory={false}/>
                            </div>
                            <div className={"w-full"}>
                                <SelectField name={"currency"} label={"Měna"} options={[
                                    {value: "CZK", label:"CZK"},
                                    {value: "EUR", label:"EUR"},
                                ]} validate={value => !value}/>
                            </div>
                            <div className={"w-1/2"}>
                                <CheckboxField name={"noEnigooSubject"} label={"Externí subjekt, který není v naší DB"} />
                            </div>
                            <div className={"w-1/2"}>
                                <CheckboxField name={"closedSubject"} label={"Ukončený subjekt"} />
                            </div>
                            <div className={"w-1/2"}>
                                <CheckboxField name={"mobileApplication"} label={"Subjekt má mobilní aplikaci"} />
                            </div>
                            <div className={"w-1/2"}>
                                <CheckboxField name={"onMarket"} label={"Subjekt se zobrazuje na marketu"} />
                            </div>

                        </div>
                        <Button text={"Uložit"} onClick={() => handleSubmit()} loading={loading}/>
                    </div>
                )
            }}/>
        )



}

export default SubjectForm
