import Header from "../../../components/My/Header";
import React, {useEffect} from "react";
import {Calendar} from "../../../components/calendar";
import ModalAdd from "./ModalAdd";
import {apiGetSubjectAction} from "./Actions";
import Loader from "../../../components/My/Loader";


const ActionCalendar = ({user}) => {
    let [modalAdd, setModalAdd] = React.useState(null);
    let [events, setEvents] = React.useState(null);
    let [loading, setLoading] = React.useState(false);
    let [actualDate, setActualDate] = React.useState(null);

    useEffect(()=>{
        getAction()
    },[])

    const getAction = (values = null) => {
        setLoading(true)
        apiGetSubjectAction(values, (data)=>{
            setEvents(data)
            setLoading(false)
        })
    }

    const changeDate = (arg) => {

    }


    if(!events) return null
    if(loading) return <Loader />
    return (
        <Header data={[{text: "Akce", isMain: true}]}>
            <Calendar events={events} onSelect={(args)=> {
                if (user?.rights?.subject?.subjectAction?.create) {
                    setModalAdd(args)
                }
            }}
                      onEventClick={(args)=>setModalAdd(args)}
                      changeDate={changeDate}
            />
            <pre>
            <ModalAdd visible={!!modalAdd} args={modalAdd} setVisible={(visible) => setModalAdd(visible)}
                      renew={getAction}/>
            </pre>
        </Header>
    )

}

export default ActionCalendar


