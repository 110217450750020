import React, {useState} from "react";
import Header from "../../components/My/Header";
import Widget from "../../components/widget";
import {useSelector} from "react-redux";

import ModalPassword from "./ModalPassword";


const MyAccount = () => {

    let {user} = useSelector(state => state.user)
    let [modalChangePassword, setModalChangePassword] = useState(false)

    const _header = () => {
        return (
            <div className="relative flex items-center justify-start h-64 w-full overflow-hidden user-widget-2">
                <img src="/images/post-3.jpg" alt="media" className="absolute inset-0 object-cover w-full h-48 z-1"/>
                <div
                    className="absolute bottom-0 left-0 right-0 w-full h-24 px-8 bg-white dark:bg-gray-800 -translate-y-7 z-2">
                    <div className="flex items-center justify-center">
                        <div className="shrink-0 w-24">
                            <img src="/images/faces/m1.png" alt="Lucas smith"
                                 className="w-24 h-24 rounded-full shadow-lg ring -translate-y-7"/>
                        </div>
                        <div className="w-full ml-4">
                            <div className="text-xs font-light text-gray-500 uppercase">Uživatel</div>
                            <div className="text-lg font-bold">{user.name}</div>
                        </div>
                        <div className="shrink-0 hidden lg:flex space-x-2">
                            {/*<Button onClick={() => setModalChangePassword(true)} text={"Změnit heslo"} size={"btn-sm"}/>*/}
                        </div>
                    </div>
                </div>
                <ModalPassword close={() => setModalChangePassword(false)} visible={modalChangePassword}/>
            </div>
        )
    }


    return (
        <Header data={[{text: "Můj účet", isMain: true}]}>
            <Widget>
                <div className="w-full ml-4">
                    <div className="text-xs font-light text-gray-500 uppercase">Uživatel</div>
                    <div className="text-lg font-bold">{user.name}</div>
                </div>
            </Widget>
        </Header>
    )
}

export default MyAccount
