import React from "react";
import InputField from "../../../../FinalForm/InputField";
import CheckboxField from "../../../../FinalForm/CheckboxField";

const CommisionOnline = () => {

    return(
        <>
            <span className={"w-full text-base font-bold mt-3"}>Fakturace prodej (FIO)</span>
            <div className={"w-full flex flex-wrap bg-orange-100 mt-1 p-2 rounded"}>
                <div className={"flex w-1/4"}>
                    <InputField label={"Vstupenky - %"} name={"invoice.ticketPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Vstupenky - Kč/vstupenka"} name={"invoice.ticketPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"invoice.ticketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>

                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Permanentky - %"} name={"invoice.seasonTicketPercent"}
                                inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Permanentky - Kč/permanentka"}
                                        name={"invoice.seasonTicketPerKs"} inputType={"number"}
                                        isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"invoice.seasonTicketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Produkty - %"} name={"invoice.productPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Produkty - Kč/produkt"} name={"invoice.productPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"invoice.productPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommisionOnline
