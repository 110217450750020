import {instance, onErrorFunction} from "../../../config/axios-cnf";


export const apiGetSubjectAction = (values = null, callback, error) => {
    instance.get(`/api/subject/action`, {params:values})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostCreateSubjectAction = (values, callback, error) => {
    instance.post(`/api/subject/action`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutEditSubjectAction = (values, callback, error) => {
    instance.put(`/api/subject/action`, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiDeleteSubjectAction = (values, callback, error) => {
    instance.delete(`/api/subject/action`, {params:values})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
