import React from "react";
import InputField from "../../../../FinalForm/InputField";
import CheckboxField from "../../../../FinalForm/CheckboxField";

const CommisionMobileApp = (props) => {

    return(
        <>
            <span className={"w-full text-base font-bold mt-1"}>Mobilní aplikace</span>
            <div className={"w-full flex flex-wrap bg-grey-200 mt-1 p-2 rounded"}>
                <div className={"flex w-1/4"}>
                    <InputField label={"Vstupenky %"} name={"mobile.ticketPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Vstupenky - Kč/vstupenka"} name={"mobile.ticketPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"mobile.ticketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Permanentky %"} name={"mobile.seasonTicketPercent"}
                                inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Permanentky - Kč/permanentka"}
                                        name={"mobile.seasonTicketPerKs"} inputType={"number"}
                                        isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"mobile.seasonTicketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Produkty -  %"} name={"mobile.productPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Produkty - Kč/produkt"} name={"mobile.productPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"mobile.productPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex w-full"}>
                            <InputField label={"Mobilní aplikace - bankovní poplatky %"} name={"mobile.bankFee"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommisionMobileApp
