import React from "react";
import SelectField from "../FinalForm/SelectField";



const SubjectPeoplePositionField = (props) => {


    return (
        <SelectField name={"positions"} isMulti={props.isMulti} editable={props.editable} options={[
            {value: 'TICKETING', label: "Ticketing"},
            {value: 'MARKETING', label: "Marketing"},
            {value: 'ECONOMIST', label: "Ekonom"},
            {value: 'DEALER', label: "Obchodník"},
            {value: 'BOSS', label: "Jednatel"},
            {value: 'IT', label: "IT specialista"},
        ]} label={"Pozice ve společnosti"} isMandatory={props.isMandatory} onEnter={props.handleSubmit}/>
    )

}


export default SubjectPeoplePositionField
