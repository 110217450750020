import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import csLocale from "@fullcalendar/core/locales/cs";
import interactionPlugin from "@fullcalendar/interaction";
import React, {useRef, useState} from "react";
import "./styles.css"


export const Calendar = ({events, getEvents, onSelect, onEventClick}) => {
    const [_events, _setEvents] = useState(events)
    const [_date, _setDate] = useState(null)

    let date_ref = useRef(null)

    const changeDate = (arg) => {
        let d = {start:arg.startStr, end:arg.endStr};
        if(date_ref.current !== d && date_ref.current !== null) {
            date_ref.current = d
           // console.log("překresluji")
        }else if(date_ref.current !== d){
            date_ref.current = d
        }
    }


    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={"dayGridMonth"}
            timeZone={"UTC"}
            headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth"
            }}
            events={_events}
            eventClick={(info) => onEventClick(info.event)}
            eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                meridiem: false,
                hour12: false,
            }}
            eventDisplay={"block"}
            displayEventEnd={true}

            eventContent={(arg) => {
                return (
                    <>
                        <div className={"fc-event-time"}>{arg.event.extendedProps.halfDay ? arg.timeText : ""}</div>
                        <div className={"fc-event-title-container"}>
                            <div className={"fc-event-title fc-sticky font-bold"}>
                                {arg?.event?.extendedProps?.name}</div>
                        </div>
                    </>
                )
            }}
            slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
                omitZeroMinute: false,
                meridiem: "narrow",
                hour12: false,
            }}
            weekends={true}
            showNonCurrentDates={true}
            slotEventOverlap={false}
            eventBackgroundColor={"#ff9800"}
            eventTextColor={"black"}
            eventBorderColor={"black"}
            locales={[csLocale]}
            selectable={true}
            select={(arg) => onSelect(arg)}
            datesSet={(arg)=> changeDate(arg)}
        />
    )
}
