import React, {useState} from "react";
import Modal from "../../../../components/My/Modal";
import {Button} from "../../../../components/My/Button";
import InputField from "../../../../FinalForm/InputField";
import {Form} from "react-final-form";
import {apiCreateUpdateSubjectPeople} from "../Actions";
import Toast from "cogo-toast";
import SelectField from "../../../../FinalForm/SelectField";


const ModalPeople = ({close, visible, people, subjectId}) => {

    let [loading, setLoading] = useState(false)
    let submit

    const acceptFunction = (values) => {
        setLoading(true)
        apiCreateUpdateSubjectPeople(subjectId, people ? people.id : null, values, (data)=>{
            close(true)
            setLoading(false)
            Toast.success("Dokončeno.")
        }, error =>{
            setLoading(false)
            switch (error?.type) {
                case "SUBJECT_EMAIL_EXIST":
                    Toast.warn("Zadaný e-mail již existuje.")
                    break;
                case "SUBJECT_PHONE_EXIST":
                    Toast.warn("Zadaný telefon již existuje.")
                    break;
                default:
                    Toast.warn("Došlo k neočekávané chybě.")
            }
        })
    }

    const getBody = () => {

        return (
            <Form onSubmit={acceptFunction} initialValues={people ? people : null} render={({handleSubmit, values}) => {
               submit = handleSubmit
                return (
                    <div>
                        <div className={"flex flex-wrap w-full"}>
                            <div className={"w-1/2"}>
                                <InputField label={"Jméno a příjmení"} name={"name"} isMandatory={true} onEnter={handleSubmit}/>
                            </div>
                            <div className={"w-1/2"}>
                                <InputField label={"E-mail"} name={"email"} isMandatory={true} onEnter={handleSubmit}/>
                            </div>
                            <div className={"w-1/2"}>
                                <InputField label={"Telefon"} name={"phone"} isMandatory={false} onEnter={handleSubmit}/>
                            </div>
                            <div className={"w-1/2"}>
                                <SelectField name={"positions"} isMulti={true} options={[
                                    {value: 'TICKETING', label: "Ticketing"},
                                    {value: 'MARKETING', label: "Marketing"},
                                    {value: 'ECONOMIST', label: "Ekonom"},
                                    {value: 'DEALER', label: "Obchodník"},
                                    {value: 'BOSS', label: "Jednatel"},
                                    {value: 'IT', label: "IT specialista"},
                                ]} label={"Pozice ve společnosti"} isMandatory={true} onEnter={handleSubmit}/>
                            </div>
                            <div className={"w-full"}>
                                <InputField label={"Poznámka"} name={"note"} isMandatory={false} onEnter={handleSubmit}/>
                            </div>
                        </div>
                    </div>
                )
            }}/>
        )
    }


    if (!visible) return null
    return (
        <>
            <Modal body={getBody()} title={!people ? "Přidat kontakt" : "Upravit kontakt"} visible={visible}
                   close={() => close(null)}
                   footer={<>
                       <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                   </>
                   }
            />
        </>
    )
}

export default ModalPeople
