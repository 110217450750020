import {Form} from "react-final-form";
import SubjectTypeField from "../../FinalForm/api/SubjectTypeField";
import {Button} from "../../components/My/Button";
import React from "react";
import Widget from "../../components/widget";
import SubjectField from "../../FinalForm/api/SubjectField";
import UsersField from "../../FinalForm/api/UsersField";
import SubjectPeoplePositionField from "../../FinalForm/SubjectPeoplePositionField";


export const Filter = ({getEvent, loading, setData}) => {
    return (
        <Widget>
            <div className={"flex flex-col"}>
                <Form onSubmit={getEvent}
                      render={({handleSubmit, form}) => (
                          <form className="form flex flex-wrap w-full">
                              <div className="lg:w-1/2 md:w-1/2 w-full">
                                  <SubjectPeoplePositionField isMulti={true} editable={true}/>
                              </div>
                              <div className="lg:w-1/2 md:w-1/2 w-full">
                                  <SubjectField name={"subject"} label={"Vyberte subjekt"} isMulti={true}
                                                onEnter={() => handleSubmit()} onChange={() => setData()}/>
                              </div>
                              <div className={"lg:w-1/2 md:w-1/2 w-full"}>
                                  <UsersField name={"keyAccount"} label={"Key account"} isMulti={true} editable={true}
                                              placeholder={"Vyberte account m."} filters={{onlyKeyAccount: true}}/>
                              </div>
                              <div className="lg:w-1/4 md:w-1/2 w-full">
                                  <SubjectTypeField name={"subjectType"} label={"Vyberte typ subjektů"} isMulti={true}
                                                    onEnter={() => handleSubmit()} onChange={() => setData()}/>
                              </div>
                              <div className="lg:w-1/4 md:w-1/2 w-full">
                                  <UsersField name={"dealer"} label={"Obchodník"} isMulti={true}
                                              placeholder={"Vyberte obchodníka"} filters={{onlyDealer: true}}/>
                              </div>

                              <div className="w-full">
                                  <Button onClick={()=> {
                                      form.change("typeExport", 'JSON')
                                      handleSubmit()
                                  }} text={"Vyhledej"} loading={loading}/>

                                  <Button onClick={()=> {
                                      form.change("typeExport", 'XLS_LIST')
                                      handleSubmit()
                                  }} text={"Stáhnout xls"} loading={loading}/>
                              </div>
                          </form>
                      )}
                />
            </div>
        </Widget>
    )
}
