import React, {useState} from "react"
import {Filter} from "./Filter";
import {apiGetContact} from "./Actions";
import TableData from "./TableData";
import FileDownload from "js-file-download";

const Contact = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    const getContact = (values) => {
        setData(null)
        setLoading(true)
        apiGetContact(values, data =>{
            if (values.typeExport === "XLS_LIST") {
                let blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
                FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
                setLoading(false)
            }else {
                setData(data)
                setLoading(false)
            }
        }, error =>{
            setLoading(false)
            setData(null)
        })
    }

    return(
        <>
            <Filter getEvent={getContact} loading={loading} setData={setData} />
            <TableData data={data}/>
        </>
    )

}

export default Contact
