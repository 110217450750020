import React, {useState} from "react";
import Widget from "../../../components/widget";
import SubjectForm from "./SubjectForm";
import {apiCreateSubject} from "./Actions";
import Toast from "cogo-toast";
import Header from "../../../components/My/Header";
import {useNavigate} from "react-router-dom";

const AddSubject = () => {

    let [loading, setLoading] = useState(false)
    let navigate = useNavigate()

    const save = (values) => {
        setLoading(true)
        apiCreateSubject(values, (data)=>{
            setLoading(false)
            Toast.success("Dokončeno")
            navigate("/system/subject/edit/" + data.id)
        }, (error)=>{
            setLoading(false)
            switch (error?.type) {
                case "SAME_ADDRESS_EXIST":
                    Toast.warn("Ulr adresa subjektu existuje u jiného subjektu.")
                    break;
                default:
                    Toast.warn("Došlo k neočekávané chybě.")
            }
        })
    }

    return (
        <Header data={[
            {text: "Subjekty", link:"/system/subject"},
            {text: "Přidat subjekt", isMain: true},
        ]}>
            <Widget>
                <SubjectForm acceptFunction={save} init={null} loading={loading} />
            </Widget>
        </Header>

    )
}

export default AddSubject
