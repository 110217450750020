import React from "react";
import Modal from "../../components/My/Modal";
import {Button} from "./Button";



const ModalAccept = ({close, visible, acceptFunction, loading, title, body}) => {


    if (!visible) return null
    return (
        <>
            <Modal body={body} title={title} visible={visible}
                   close={() => close(null)}
                   footer={<>
                       <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={() => acceptFunction()} loading={loading}/>
                   </>
                   }
            />
        </>
    )
}

export default ModalAccept
