import React from "react";
import CheckboxField from "../../../../FinalForm/CheckboxField";

const ExtendedList = (props) => {
  return(
      <>
          <span className={"w-full text-base font-bold mt-3"}>Listy a data zobrazené v excelu</span>
          <div className={"w-full flex flex-wrap bg-orange-100 mt-1 p-2 rounded"}>
              <div className={"flex w-full mt-1 ml-1"}>
                  <CheckboxField name={"exportExcel.tickets"}
                                 label={"Prodané vstupenky a permanentky, produkty, členství, mobilní aplikace"}/>
              </div>
              <div className={"flex w-full mt-1 ml-1"}>
                  <CheckboxField name={"exportExcel.mobileCreditState"} label={"Stavy mobilního kreditu"}/>
              </div>
              <div className={"flex w-full mt-1 ml-1"}>
                  <CheckboxField name={"exportExcel.invoice"} label={"Faktury"}/>
              </div>
              <div className={"flex w-full mt-1 ml-1"}>
                  <CheckboxField name={"exportExcel.invoiceTaxDocument"} label={"Daňové doklady"}/>
              </div>
          </div>
      </>
  )
}

export default ExtendedList
