import React, {useEffect, useState} from "react"
import Widget from "../../components/widget";
import {apiGetDashboard} from "./Actions";

import Contract from "./Component/Contract";


const Dashboard = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)


    useEffect(() => {
        get()
    }, [])

    const get = () => {
       setLoading(true)
        apiGetDashboard((data) => {
            setData(data)
            setLoading(false)
        }, error => {
            setLoading(false)
        })
    }

    if (loading) return <div>Loader</div>
    if(!data) return null

    return (
        <div className={"flex flex-wrap w-full"}>
            <Contract data={data} />
        </div>
    )

}

export default Dashboard
