import React, {useState} from "react";
import Modal from "../../components/My/Modal";
import {Button} from "../../components/My/Button";
import Toast from "cogo-toast";
import { apiRecalculateBilling} from "./Actions";
import {Form} from "react-final-form";
import CheckboxField from "../../FinalForm/CheckboxField";


const ModalRecalculateBilling = ({close, visible}) => {

    let [loading, setLoading] = useState(false)

    let item = visible
    let submit

    const recalculate = (values) => {
        setLoading(true)
        apiRecalculateBilling(item.id, values, (data) => {
            close(true)
            setLoading(false)
            Toast.success("Dokončeno.")
        }, error => {
            setLoading(false)
            Toast.warn("Došlo k chybě.")
        })
    }

    const getBody = () => {
        return (
            <Form onSubmit={recalculate} render={({handleSubmit}) => {
                submit = handleSubmit
                return (
                    <div className={"flex flex-wrap w-full"}>
                        <div className={"w-1/2"}>
                            <CheckboxField label={"Uložit vygenerované XLS do složek One drive."} name={"saveFileToOneDrive"}/>
                        </div>
                        <div className={"w-1/2"}>
                            <CheckboxField label={"Vygenerované vyúčtování uzamknout."} name={"finishBilling"}/>
                        </div>
                        <div className={"w-1/2"}>
                            <CheckboxField label={"Označit subjekty, které neprodávaly jako neaktivní."} name={"markNonActiveSubject"}/>
                        </div>
                    </div>
                )
            }}/>
        )
    }


    if (!item) return null
    return (
        <>
            <Modal body={getBody()} title={"Přepočítat vyúčtování"} visible={visible} close={() => close(false)}
                   footer={
                       <>
                           <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                           <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                       </>
                   }
            />
        </>
    )
}

export default ModalRecalculateBilling
