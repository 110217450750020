import React from "react";
import InputField from "../../../../FinalForm/InputField";
import CheckboxField from "../../../../FinalForm/CheckboxField";

const CommisionCashier = () => {

    return(
        <>
            <span className={"w-full text-base font-bold mt-3"}>Pokladní prodej</span>
            <div className={"w-full flex flex-wrap bg-orange-100 mt-1 p-2 rounded"}>
                <div className={"flex w-1/4"}>
                    <InputField label={"Vstupenky - %"} name={"cashier.ticketPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Vstupenky - Kč/vstupenka"} name={"cashier.ticketPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"cashier.ticketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Permanentky - %"} name={"cashier.seasonTicketPercent"}
                                inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Permanentky - Kč/vstupenka"}
                                        name={"cashier.seasonTicketPerKs"} inputType={"number"}
                                        isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"cashier.seasonTicketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Rezer. vytvořené online - vyzvednuto pokladna %"}
                                name={"cashier.creaOnlinePickCashPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Rezer. vytvořené admin - vyzvednuto pokladna %"}
                                name={"cashier.creaAdmPickCashPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Rezer. vytvořené pokladna - vyzvednuto pokladna %"}
                                name={"cashier.creaCashPickCashPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Rezer. vyzvednuté v pokladně Kč/vst"}
                                name={"cashier.ticketReservationPerPs"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Produkty - %"} name={"cashier.productPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Produkty - Kč/produkt"} name={"cashier.productPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"cashier.productPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommisionCashier
