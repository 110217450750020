import React, {useEffect, useState} from 'react';
import Modal from "../../../components/My/Modal";
import {Form} from "react-final-form";
import InputField from "../../../FinalForm/InputField";
import {Button} from "../../../components/My/Button";
import SubjectField from "../../../FinalForm/api/SubjectField";
import UsersField from "../../../FinalForm/api/UsersField";
import DateTimeField from "../../../FinalForm/DateTimeField";
import {
    apiDeleteSubjectAction,
    apiGetSubjectAction,
    apiPostCreateSubjectAction,
    apiPutEditSubjectAction
} from "./Actions";
import moment from "moment";
import Loader from "../../../components/My/Loader";
import ModalAccept from "../../../components/My/ModalAccept";
import {useSelector} from "react-redux";
import Toast from "cogo-toast";


const ModalAdd = ({visible, setVisible, args, renew}) => {

    let submit
    let [action, setAction] = useState(null)
    let [loading, setLoading] = useState(false)
    let [visibleAccept, setVisibleAccept] = useState(false)
    let {user} = useSelector(state => state.user)

    useEffect(() => {
        args?.id && getAction()
    }, [args])

    const getAction = () => {
        setLoading(true)
        apiGetSubjectAction({actionId: args?.id, extended: true}, (data) => {
            setAction(data)
            setLoading(false)
        })
    }

    const acceptFunction = (values) => {
        let fnc = null
        if(args?.id){
            fnc = apiPutEditSubjectAction;
        }else{
            fnc = apiPostCreateSubjectAction;
        }

        fnc(values, (data) => {
            setVisible(false)
            renew()
            Toast.success("Dokončeno.")
        }, error => {
            Toast.warn("Došlo k chybě.")
        })
    }

    const deleteAction = () => {
        apiDeleteSubjectAction({actionId: args?.id}, (data) => {
            setVisible(false)
            setVisibleAccept(false)
            renew()
            Toast.success("Dokončeno.")
        }, error => {
            Toast.warn("Došlo k chybě.")
        })
    }

    const getInit = () => {
        if (args?.id && action){
            action.start = moment(action?.start).format("YYYY-MM-DD")
            action.end = moment(action?.end).format("YYYY-MM-DD")
            return action
        }
        return {
            start: moment(args.start).format("YYYY-MM-DD"),
            end: moment(args.end).format("YYYY-MM-DD"),
            data: {
                countReader: 0,
                countSunmi: 0,
                countOnlineCashdesk: 0,
                countPaymentTerminal: 0,
                countKiosk: 0,
                countSunmiBattery: 0
            }
        }
    }

    const getBody = () => {
        if (loading) return <Loader/>

        return (
            <div className={"max-h-screen"}>
                <Form onSubmit={acceptFunction} initialValues={getInit()} render={({handleSubmit, values}) => {
                    submit = handleSubmit
                    return (
                        <div>
                            <div className={"flex flex-wrap w-full"}>
                                <div className={"w-1/2"}>
                                    <DateTimeField label={"Datum od"} name={"start"} isMandatory={true}
                                                   editable={user?.rights?.subject?.subjectAction?.create}/>
                                </div>
                                <div className={"w-1/2"}>
                                    <DateTimeField label={"Datum od"} name={"end"} isMandatory={true}
                                        editable={user?.rights?.subject?.subjectAction?.create}/>
                                </div>
                                <div className={"w-1/3"}>
                                    <InputField label={"Název akce"} name={"name"} isMandatory={true}
                                                editable={user?.rights?.subject?.subjectAction?.create}/>
                                </div>
                                <div className={"w-1/3"}>
                                    <SubjectField label={"Subjekt"} name={"subjectId"} isMandatory={true}
                                                  editable={user?.rights?.subject?.subjectAction?.create}/>
                                </div>
                                <div className={"w-1/3"}>
                                    <UsersField label={"Odpovědná osoba"} name={"keyAccountId"} isMandatory={true}
                                                editable={user?.rights?.subject?.subjectAction?.create}/>
                                </div>
                                <div className={"w-full"}>
                                    <InputField inputType={"textarea"} label={"Poznámky k akci"}
                                                editable={user?.rights?.subject?.subjectAction?.create}
                                                name={"data.aboutAction"} isMandatory={false}/>
                                </div>
                                <div className={"w-1/2"}>
                                    <div className={"w-full"}>
                                        <InputField label={"Počet čteček"} name={"data.countReader"}
                                                    editable={user?.rights?.subject?.subjectAction?.create}
                                                    isMandatory={false}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField label={"Počet sunmi pokladen"} name={"data.countSunmi"}
                                                    editable={user?.rights?.subject?.subjectAction?.create}
                                                    isMandatory={false}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField label={"Počet on-line pokladen"} name={"data.countOnlineCashdesk"}
                                                    editable={user?.rights?.subject?.subjectAction?.create}
                                                    isMandatory={false}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField label={"Počet platební terminály"}
                                                    name={"data.countPaymentTerminal"}
                                                    editable={user?.rights?.subject?.subjectAction?.create}
                                                    isMandatory={false}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField label={"Počet kiosky"} name={"data.countKiosk"}
                                                    editable={user?.rights?.subject?.subjectAction?.create}
                                                    isMandatory={false}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField label={"Počet baterií sunmi"} name={"data.countSunmiBattery"}
                                                    editable={user?.rights?.subject?.subjectAction?.create}
                                                    isMandatory={false}/>
                                    </div>
                                </div>
                                <div className={"w-1/2"}>
                                    <div className={"w-full"}>
                                        <UsersField label={"Lidé na akci"} name={"peopleOnAction"} isMandatory={false}
                                                    isMulti={true}
                                                    editable={user?.rights?.subject?.subjectAction?.create}/>
                                    </div>
                                    <div className={"w-full"}>
                                        <InputField inputType={"textarea"} label={"Poznámky k ubytování"}
                                                    name={"data.aboutAccomodation"} isMandatory={false}
                                                    editable={user?.rights?.subject?.subjectAction?.create}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
                />
                <ModalAccept body={"Opravdu chcete smazat akci ? "} visible={visibleAccept}
                             close={() => setVisibleAccept(false)}
                             acceptFunction={deleteAction} title={"Upozornění"}/>
            </div>
        )
    }

    if (!visible) return null;
    if (visible) {
        return <Modal visible={visible} title={args?.id ? "Detail akce": "Přidat akci"} close={() => setVisible(false)}
                      body={getBody()}
                      footer={
                          <div className={"flex justify-between w-full"}>

                                  <Button text={"Vymazat akci"} onClick={() => setVisibleAccept(true)}
                                    color={"red"} outline={true}
                                      hasRight={action && user?.rights?.subject?.subjectAction?.delete === true}/>
                              <div class="ml-auto">
                                  <Button text={"Uložit"} onClick={() => submit()}
                                    hasRight={user?.rights?.subject?.subjectAction?.create} />
                              </div>
                          </div>
                      }/>

    }


}

export default ModalAdd
