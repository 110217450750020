import React from "react";

import {IconTabs} from "../../../components/tabs";
import Datatable from "../../../components/datatable";
import moment from "moment";
import Widget from "../../../components/widget";

const Contract = ({data}) => {


    if(!data?.contract) return null


    let columns = [
        {Header: 'Subjekt', accessor: d=> <>
            <div title={d.dealerName}>{d.subjectName}</div>
        </>},
        {Header: 'Datum konce', accessor: d=> <div>{moment(d.contractTo).format("ll")}</div>}
    ];

    const getTabs = () => {
        let i = 0;
        let tabs = [];
        if(data?.contract?.after){
            tabs.push({
                index: i,
                title: <div>Po ukončení !!</div>,
                content: <div><Datatable data={data?.contract?.after} columns={columns} /></div>

            })
            i++
        }
        if(data?.contract?.threeMonth){
            tabs.push({
                index: i,
                title: <div>Méně jak 3 měsíce</div>,
                content: <div><Datatable data={data?.contract?.threeMonth} columns={columns} /></div>

            })
            i++
        }
        if(data?.contract?.sixMonth){
            tabs.push({
                index: i,
                title: <div>Méně jak 6 měsíců</div>,
                content: <div><Datatable data={data?.contract?.sixMonth} columns={columns}/></div>

            })
            i++
        }

        return tabs;
    }

    return (
        <div className={"w-1/2"}>
            <Widget title={"Expirace smluv"} description={"Smlouvy blížící se ke svému konci."}>
                <IconTabs tabs={getTabs()}/>
            </Widget>
        </div>
    )

}

export default Contract
