import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import moment from "moment";


const DateTimeField = (props) => {


    const renderError = (meta, value) => {

        if (meta.error && meta.touched) {
            if(props.validateFun && props.valueToCompare){
                let error= props.validateFun(value,props.valueToCompare);
                if(error==="") return null;
                else{
                    return <div className={"text-red-500"}>{error}</div>;
                }
            }
            if (!value || value === "") {
                return <div className={"text-red-500"}>Povinné pole</div>;
            }
        }
        return null;
    };

    const _renderClassName = (meta, value) => {
        if (props.disabled) return 'form-input w-full text-sm';
        let className = `form-input w-full text-sm `;
        if (meta.touched && meta.error) {
            className += "border-red-500 ";
        } else if (value && props.minLength && value.length < props.minLength) {
            className += "border-red-500 ";
        } else if (value && props.maxLength && value.length > props.maxLength) {
            className += "border-red-500 ";
        } else if (props.checkWithValue && value !== props.checkWithValue) {
            className += "border-red-500 ";
        } else if (value) {
            className += "border-green-500 ";
        }

        return className;
    };

    const editValue = (val) => {
        let value = null;
        if (!val) return value;
        else if (props.type && props.type === "from") {
            return val.slice(0, val.indexOf("T")) + "T00:00";
        } else if (props.type && props.type === "to") {
            return val.slice(0, val.indexOf("T")) + "T23:59";
        } else {
            return val;
        }
    }

    return (
        <Field
            name={props.name}
            validate={(value) => {
                if(props.validateFun && props.valueToCompare){
                    if(props.validateFun(value,props.valueToCompare)===""){

                    }else{
                        return true;
                    }
                }
                if (props.isMandatory) {
                    if (!value) return true;
                }

            }}
            render={(_props) => {
                if(!props.editable){
                    return(
                    <>
                        <div className="font-bold flex flex-col">
                            {props.label + ": "}
                        </div>
                        <div className={"text-base"}>{moment(_props.input.value).format("llll")}</div>
                    </>)

                } else {

                    return (
                        <div className={"w-full text-sm text-gray-900 p-1"}>
                        {props.label && (
                                <div className="font-bold flex flex-col">
                                    {props.label + ": "}
                                </div>

                            )}
                            <input placeholder={props.placeholder ? props.placeholder : "Zadejte"}
                                   type={props.showTimeSelect ? "datetime-local" : "date"}
                                   className={_renderClassName(_props.meta, _props.input.value)}
                                   disabled={props.disabled}
                                   maxLength={props.maxLength ? props.maxLength : 20000}
                                   value={_props.input.value}
                                   onChange={(e) => {
                                       if (props.onChange) {
                                           props.onChange(props.name, e.target.value);
                                       }
                                       if (!_props.input.value) {
                                           e.target.value = editValue(e.target.value);
                                       }
                                       _props.input.onChange(e.target.value);
                                   }}
                                   id={props.id}
                                   onKeyDown={({key}) => {
                                       if (key === "Enter") {
                                           if (props.onEnter) props.onEnter();
                                       }
                                   }}
                            />

                            {renderError(_props.meta, _props.input.value)}
                        </div>
                    );
                }
            }}
        />
    );
};
DateTimeField.defaultProps = {
    inputType: "from",
    disabled: false,
    showTimeSelect:false,
    editable: true
};

DateTimeField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    note: PropTypes.string,
    type: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    onEnter: PropTypes.func,
    editable: PropTypes.bool
};

export default DateTimeField;
