import React, {useState} from "react"
import {Button} from "../../../../components/My/Button";
import Datatable from "../../../../components/datatable";
import ModalBank from "./ModalBank";


const Bank = ({data, renew}) => {

    let [modalBank, setModalBank] = useState(false)

    let columns = [
        {Header: 'Název banky', accessor: "name"},
        {Header: 'Prefix', accessor: "key"},
        {Header: 'Měna', accessor: "currency"},
    ];

    return (
        <div>
            <Datatable data={data.bank} columns={columns}/>
            <Button text={"Přidat banku"} onClick={()=>setModalBank(true)} />
            <ModalBank visible={modalBank} close={(isRenew)=>{
                if(isRenew) renew()
                setModalBank(false)}
            } subjectId={data.id}/>
        </div>
    )

}

export default Bank
